import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { Container } from '../components/Container'
import { Footer } from '../features/Footer'
import { Logo } from '../features/BlackLogo'
import { Text, H1, H3, H4 } from '../components/Typography'

const TextContainer = styled.section`
  max-width: 630px;
  margin: 0 auto;
`

const StyledText = styled(Text)`
  font-size: 14px;
  padding-bottom: 25px;
  line-height: 1.5;
`

const StyledLink = styled.a`
  color: #0078d6;
  transition: color linear 0.2s;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #0045a3;
  }
`

const StyledH3 = styled(H3)`
  padding-bottom: 25px;
`

const StyledH4 = styled(H4)`
  padding-bottom: 25px;
`

const StyledUl = styled.ul`
  list-style-type: upper-roman;
`

const StyledLi = styled.li`
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.5;
`

const StyledInnerLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.textPrimary};
`

const PrivacyPolicy = () => (
  <>
    <Container pt="20px">
      <Link to="/">
        <Logo />
      </Link>
    </Container>
    <TextContainer>
      <StyledInnerLink to="/">
        <Text fontSize="14px">Back to home</Text>
      </StyledInnerLink>
      <H1 fontFamily="Montserrat">Privacy policy</H1>
      <StyledText>Last updated on 29 April 2020</StyledText>
      <StyledText>
        This privacy policy (hereinafter – <b>“Policy”</b>) describes and provides information on
        how Globalhop Ltd (hereinafter – <b>“Safeternet”</b>, <b>“Globalhop” “we”</b>, or{' '}
        <b>“us”</b>) gathers your personal data while you are using the Safeternet application
        (hereinafter –<b>“Application”</b> or <b>“App”</b>) as well as on how such data may be used.
        This Policy also describes the rights and choices available to you in regards to the
        information collected.
      </StyledText>
      <StyledText>
        We may amend and/or update this Policy from time to time. We will notify you by a pop-up on
        the application or by other means deemed fit in advance of any considerable changes being
        made to this Policy.{' '}
      </StyledText>
      <StyledText>
        In case of any questions related to this Policy or means of collecting your personal
        information, please contact us at:{' '}
        <StyledLink href="mailto:hello@safeternet.com">hello@safeternet.com</StyledLink>.
      </StyledText>
      <StyledH3>APPLICATION USERS’ DATA</StyledH3>
      <StyledH4>The application</StyledH4>
      <StyledText>
        The Safeternet App is an DNS changer application allowing its users to filter out and block
        hazardous, malicious and corrupting internet websites. The DNS servers used within the
        Application are controlled and provided by third-party DNS providers, while the Application
        acts as a tool allowing you to set up and make use of such DNS servers in a simple and swift
        way.{' '}
      </StyledText>
      <StyledText>
        For the purpose of providing you with access to the Application and ensuring its intended
        performance, and the proper functioning of integrated SDK we must collect a limited amount
        of your personal data related to you and your device:
      </StyledText>
      <StyledUl>
        <StyledLi>
          <Text fontSize="14px">The type of operating system used;</Text>
        </StyledLi>
        <StyledLi>
          <Text fontSize="14px">Device’s charging state;</Text>
        </StyledLi>
        <StyledLi>
          <Text fontSize="14px">Device’s battery status;</Text>
        </StyledLi>
        <StyledLi>
          <Text fontSize="14px">
            Network information regarding the type of connection used on the device (ethernet,
            wi-fi, etc.);
          </Text>
        </StyledLi>
        <StyledLi>
          <Text fontSize="14px">Your IP address.</Text>
        </StyledLi>
      </StyledUl>
      <StyledText>
        The personal information listed above is collected solely on the basis of your explicit
        consent, given to us by you accessing and/or using the Application. The information
        collected is limited to what is strictly necessary for the proper functioning of the
        Application and does not include any sensitive personal information.
      </StyledText>
      <StyledText>
        In case of your communication with us via e-mail, we will also collect your e-mail details
        and the information within such correspondence in order to provide you with a response and
        assistance with any issue that you may have. The collection of such information is based on
        our legitimate interest of ensuring proper and satisfactory communication and user support
        to the Application’s users.
      </StyledText>
      <StyledH4>Website</StyledH4>
      <StyledText>
        When you download the Application through its dedicated website we automatically collect
        certain information about you via Google analytics. The basis of such data processing is
        based on our legitimate interest of analysing and improving our services.
      </StyledText>
      <StyledH4>SDK</StyledH4>
      <StyledText>
        We are working hard to keep the Application low cost or free to all users. For this end, we
        incorporate trusted Globalhop SDK into the Application. This SDK allows us to commercialize
        your use of the Application by providing us access to your device and using a little amount
        of your network traffic and device resources, thus bringing benefit to all parties involved.
        For more information about Globalhop SDK and personal information it may collect, please see
        Globalhop Privacy Policy.
      </StyledText>
      <StyledH3>DATA SHARING</StyledH3>
      <StyledText>
        In order to be able to deliver our services, we use data storage provided to us by our
        service providers (data processors). Such providers are obligated to keep your data secure,
        not disclose your information to any third parties and do not have the right to access your
        data for any purpose bar data storage.Other than our data processing service providers, we
        will not share, disclose and/or entrust your personal data to any third-party except if:
      </StyledText>
      <StyledUl>
        <StyledLi>
          <Text fontSize="14px">
            The disclosure of your personal data is requested by law in order to comply with an
            order of a court, to enforce our rights under an agreement between as, otherwise protect
            the rights of our company or its stakeholders.
          </Text>
        </StyledLi>
        <StyledLi>
          <Text fontSize="14px">We receive your request or authorization to do so.</Text>
        </StyledLi>
        <StyledLi>
          <Text fontSize="14px">
            Such disclosure is necessary for our agents to function on our behalf.
          </Text>
        </StyledLi>
        <StyledLi>
          <Text fontSize="14px">It is necessary to address and emergency.</Text>
        </StyledLi>
      </StyledUl>
      <StyledH3>DATA STORAGE AND SECURITY</StyledH3>
      <StyledText>
        The personal data we process as a result of your use of the Application will not be stored
        for a longer time period than necessary in order to provide you the Application’s services.
        All your personal data that we collect and/or process is subject to high level of care on
        our part. For this purpose, we take measures, such as updates, testing and/or restriction of
        access, in order to ensure that your information is being kept in a lawful manner and with a
        high level of care.
      </StyledText>
      <StyledH3>YOUR PERSONAL DATA RIGHTS</StyledH3>
      <StyledText>
        You may choose to exercise any of the following rights related to your personal data by
        contacting us at{' '}
        <StyledLink href="mailto:hello@safeternet.com">hello@safeternet.com</StyledLink>:
      </StyledText>
      <StyledUl>
        <StyledLi>
          <Text>
            Right to delete. You have the right to request us to delete the personal data that we
            have collected about you. We will satisfy such request if: (a) at the time of the
            request, the data is not needed for the purposes it was collected for; (b) the data is
            necessary for us in order to comply with a legal obligation or an establishment,
            exercise or defence of a legal claim.
          </Text>
        </StyledLi>
        <StyledLi>
          <Text>
            Right to access. You may request us to provide access to your personal data collected by
            us. Since we collect a limited amount of your data, we may request to receive additional
            information to be able to identify you, enabling us to satisfy your request. Such
            information would be used for the sole purpose of helping exercise this right.
          </Text>
        </StyledLi>
        <StyledLi>
          <Text>
            Right to amend. You can request us to edit any outdated or misleading information about
            you.
          </Text>
        </StyledLi>
      </StyledUl>
      <StyledText>
        If you are based in EEA, you are also entitled the following rights under the General Data
        Protection Regulation:
      </StyledText>
      <StyledUl>
        <StyledLi>
          <Text>Receiving your data in an accessible format;</Text>
        </StyledLi>
        <StyledLi>
          <Text>Ask to transfer your data to another data controller;</Text>
        </StyledLi>
        <StyledLi>
          <Text>Limit the unlawful processing of your personal data;</Text>
        </StyledLi>
        <StyledLi>
          <Text>
            File a complaint regarding our use of your personal data to local supervisory
            authorities;
          </Text>
        </StyledLi>
        <StyledLi>
          <Text>
            Where data processing is based on our legitimate interests, you can object to us
            processing your data if in doing so we breach your fundamental rights and freedoms.
          </Text>
        </StyledLi>
      </StyledUl>
      <StyledH3>INFORMATION OF CHILDREN</StyledH3>
      <StyledText>
        We do not knowingly attempt to solicit or receive information from children under the age of
        14 or any other age requiring consent of a child’s parent or legal guardian. If we process
        any personal information of a child by accident, we will delete and take all measures
        necessary to dispose of such information as soon as we become aware of such situation. If
        you are a parent or legal guardian of child whose information was provided to us without
        your consent, do not hesitate to contact us so we can take immediate actions in order to
        stop processing and delete all of such child’s data collected by us.
      </StyledText>
    </TextContainer>
    <Footer />
  </>
)

export default PrivacyPolicy
